const env = {
  REACT_APP_CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
  REACT_APP_API_VERSION: process.env.REACT_APP_API_VERSION,
  REACT_APP_BACK_END_END_POINT: process.env.REACT_APP_BACK_END_END_POINT,
  REACT_APP_AIN_GUIDE_URL: process.env.REACT_APP_AIN_GUIDE_URL,
  REACT_APP_CPM_GUIDE_URL: process.env.REACT_APP_CPM_GUIDE_URL,
  REACT_APP_APR_GUIDE_URL: process.env.REACT_APP_APR_GUIDE_URL,
  REACT_APP_TAG_GUIDE_URL: process.env.REACT_APP_TAG_GUIDE_URL,
  REACT_APP_SPT_GUIDE_URL: process.env.REACT_APP_SPT_GUIDE_URL,
  REACT_APP_AGN_GUIDE_URL: process.env.REACT_APP_AGN_GUIDE_URL,
  REACT_APP_TTD_GUIDE_URL: process.env.REACT_APP_TTD_GUIDE_URL,
  REACT_APP_DDGA_GUIDE_URL: process.env.REACT_APP_DDGA_GUIDE_URL,
  REACT_APP_FVP_GUIDE_URL: process.env.REACT_APP_FVP_GUIDE_URL,
};

export default env;
