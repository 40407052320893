import { useContext, useEffect, useState } from 'react';
import { Tab, TabList, ThemeProvider } from 'monday-ui-react-core';
import UserGuideForm from '../components/user-guide';
import BillingForm from '../components/billing';
import OperationCounterForm from '../components/operation-counter';
import { AppContext } from '../context/app-context';
import Intercom from '@intercom/messenger-js-sdk';

export default function DashboardPage() {
  const [currentTab, setCurrentTab] = useState(0);
  const [tabList, setTabList] = useState<any>();
  const appContext: any = useContext(AppContext);
  console.log(appContext);
  Intercom({
    app_id: 'efnew8bu',
    alignment: 'left',
    horizontal_padding: 100,
    vertical_padding: 40,
  });
  function getTabList() {
    if (
      appContext?.appId === '10032083' ||
      appContext?.appId === '10032081' ||
      appContext?.appId === '10032082'
    ) {
      setTabList(['User Guide', 'Billing']);
    } else {
      setTabList(['User Guide', 'Operation Counter', 'Billing']);
    }
  }

  useEffect(() => {
    getTabList();
  }, [appContext]);

  return (
    <ThemeProvider
      systemTheme={appContext?.mondayContext?.theme}
      themeConfig={appContext?.mondayContext?.themeConfig}>
      <main className="mt-8 w-4/5 mx-auto">
        <div className="w-4/5 mx-auto">
          <TabList
            tabType="stretched"
            onTabChange={(tabId) => setCurrentTab(tabId)}>
            {tabList?.map((tab: string, index: number) => (
              <Tab key={index}>{tab}</Tab>
            ))}
            {/* <Tab>User Guide</Tab>
            {appContext?.appId !== '10032083' &&
              appContext?.appId !== '10032081' &&
              appContext?.appId !== '10032082' && <Tab>Operation Counter</Tab>}
            <Tab>Billing</Tab> */}
          </TabList>
        </div>
        <div className="mt-6">
          {currentTab === 0 && <UserGuideForm></UserGuideForm>}
          {currentTab === 0 && <UserGuideForm />}
          {currentTab === 1 &&
            appContext?.appId !== '10032083' &&
            appContext?.appId !== '10032081' &&
            appContext?.appId !== '10032082' && <OperationCounterForm />}
          {currentTab === 2 && <BillingForm />}
          {currentTab === 1 &&
            (appContext?.appId === '10032083' ||
              appContext?.appId === '10032081' ||
              appContext?.appId === '10032082') && <BillingForm />}
        </div>
      </main>
    </ThemeProvider>
  );
}
