"use client";
import MondayService from "../utils/monday-service";
import React, { createContext, useMemo, useState } from "react";

const AppContext = createContext({});

const AppProvider = ({ children }) => {
  const [appContext, setAppContext] = useState<any>({});
  useMemo(async () => {
    const pathNames = window.location.pathname?.split("/");
    MondayService.appId = pathNames?.[1];
    MondayService.clientId = pathNames?.[2];
    const mondayContext: any = await MondayService.getMondayContext();
    const linkOC =
      mondayContext?.account?.id &&
      MondayService.appId &&
      MondayService.clientId
        ? await MondayService.getLinkOC(mondayContext.account.id)
        : null;
    const linkUserGuide = MondayService.appId
      ? await MondayService.getLinkUserGuide()
      : null;
    const _appContext = {
      mondayContext,
      linkOC,
      linkUserGuide,
      appId: MondayService.appId,
      clientId: MondayService.clientId,
    };
    setAppContext(_appContext);

  }, []);

  return (
    <AppContext.Provider value={appContext}>{children}</AppContext.Provider>
  );
};

export { AppContext, AppProvider };
