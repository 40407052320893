import MondayService from "../utils/monday-service";
import { Button } from "monday-ui-react-core";
import React from "react";

export default function BillingForm() {
  async function handleBilling() {
    MondayService.showPlan();
  }
  return (
    <div className="mx-auto w-4/5">
      <Button onClick={handleBilling}>Manage Billing</Button>
    </div>
  );
}
