import { AppContext } from "../context/app-context";
import { Loader, Text } from "monday-ui-react-core";
import React, { useContext, useState } from "react";

export default function UserGuideForm() {
  const appContext: any = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  check();
  function check() {
    const intervalId = setInterval(() => {
      if (appContext?.linkUserGuide?.length) {
        setLoading(false);
        clearInterval(intervalId);
      }
    }, 10); // Kiểm tra mỗi 100ms

    const timeoutId = setTimeout(() => {
      setLoading(false);
      clearInterval(intervalId);
    }, 3000); // Sau 3 giây dừng loading

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }

  if (loading) {
    return (
      <div className="mt-16 mx-auto w-fit">
        <Loader hasBackground={true} size={64} />
      </div>
    );
  } else if (!appContext?.linkUserGuide?.length) {
    return (
      <div className="w-4/5 mx-auto">
        <Text color={Text.colors.PRIMARY} type={Text.types.TEXT2}>
          We are updating the User Guide for a while. Please be patient, or you
          can contact our support team at{" "}
          <a href="mailto: support@b-lab.app"> support@b-lab.app </a> for
          further assistance.
        </Text>
      </div>
    );
  }

  return (
    appContext?.linkUserGuide?.length && (
      <div className="mx-auto mb-12">
        <iframe
          title="linkuserguide"
          src={appContext.linkUserGuide}
          className="w-full h-screen border-0"
        ></iframe>
      </div>
    )
  );
}
